import AnchorButton from "@/components/button/AnchorButton";
import Button from "@/components/button/Button";
import WihvLoader from "@/components/loading-component/WihvLoader";
import UnsavedChangesGuard from "@/components/unsaved-changes-guard/UnsavedChangesGuard";
import { ConfigContext } from "@/context/ConfigContext";
import { AttributeFeedback } from "@/general/model/attribute-feedback";
import { Feedback } from "@/general/model/feedback";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import AttributeEvaluation from "./components/attribute-evaluation/AttributeEvaluation";
import './FeedbackOverview.scss';

function FeedbackOverview() {

    useDocumentTitle(t('documentTitles:feedback'));

    const configContext = useContext(ConfigContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hash = queryParams.get('hash');

    const [loading, setLoading] = useState<boolean>(true);
    const [started, setStarted] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [feedback, setFeedback] = useState<Feedback>(null);

    useEffect(() => {
        if (hash) {
            validateAndRetrieveFeedback(hash);
        } else {
            setError(t('feedback:noHashFound'));
            setLoading(false);
        }
    }, []);

    async function validateAndRetrieveFeedback(hash) {

        try {
            const response = await fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.feedbacks.validate}`, {
                credentials: "same-origin",
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
                },
                body: JSON.stringify({
                    hash: hash
                })
            });

            if (!response.ok) {
                throw new Error("Invalid response");
            }

            const data = await response.json();
            setFeedback(data);
        } catch (error) {
            setError(error.toString());
        } finally {
            setLoading(false);
        }
    };

    function handleSaveFeedback(feedbacks: AttributeFeedback[]) {

        const updatedFeedback = {
            ...feedback,
            attributeFeedbacks: feedbacks
        } as Feedback;

        setIsSubmitting(true);

        return fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.feedbacks.detail.replace('{id}', feedback?.id)}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(updatedFeedback)
        })
            .then((response) => {
                if (response.ok) {
                    setSaved(true);
                    toast.error(t('feedback:toasts:saveFeedbackSuccess'));
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .catch((error) => {
                toast.error(t('feedback:toasts:saveFeedbackFailed'));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <div className="feedback-overview">
            <Container fluid className='py-5'>
                <Container>
                    <Row>
                        <Col sm={12} className="d-flex justify-content-center">
                            <img src="/assets/img/werk-in-het-vooruitzicht-fullcolor.svg" alt="WIHV" className="logo" />
                        </Col>
                    </Row>

                    {loading &&
                        <Row className='content d-flex justify-content-center'>
                            <Col sm={12} md={6} className="overview-card">
                                <WihvLoader />
                                <h1 className="pb-3 pt-5">{t('feedback:loadingTitle')}</h1>
                                <p className="pb-4">{t('feedback:loadingDescription')}</p>
                            </Col>
                        </Row>
                    }

                    {!started && feedback &&
                        <Row className='content d-flex justify-content-center'>
                            <Col sm={12} lg={6} className="overview-card">
                                <h1 className="title pb-3">{t('feedback:startTitle', { name: feedback.createdBy })}</h1>
                                <h2 className="pb-3">{t('feedback:startSubtitle')}</h2>
                                <p className="pb-4">{t('feedback:startDescription')}</p>
                                <Button text={t('feedback:startBtn')} icon="chevron-right" onClick={() => setStarted(true)} />
                            </Col>
                        </Row>
                    }

                    {error &&
                        <Row className='content d-flex justify-content-center'>
                            <Col sm={12} md={6} className="overview-card">
                                <h1 className="pb-3 pt-4">{t('feedback:noEvaluationTitle')}</h1>
                                <p className="pb-4">{t('feedback:noEvaluationDescription')}</p>
                            </Col>
                        </Row>
                    }

                    {started && feedback && !saved &&
                        <Row>
                            <Col sm={12} className='pt-5 d-flex flex-column align-items-center text-center'>
                                <h1 className="title pb-3">{t('feedback:title')}</h1>
                                <p className="description">{t('feedback:description')}</p>
                            </Col>
                            <AttributeEvaluation feedback={feedback} isSubmitting={isSubmitting} onSave={(feedbacks) => handleSaveFeedback(feedbacks)} />
                        </Row>
                    }

                    {saved &&
                        <Row className='content d-flex justify-content-center'>
                            <Col sm={12} md={6} className="overview-card">
                                <h1 className="pb-3 pt-4">{t('feedback:savedTitle')}</h1>
                                <p className="pb-4">{t('feedback:savedDescription')}</p>
                                <AnchorButton icon="chevron-right" href="https://werkinhetvooruitzicht.nl" text={t('feedback:savedLink')} />
                            </Col>
                        </Row>
                    }

                </Container>
            </Container>

            <div className="background-footer">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>

            <UnsavedChangesGuard shouldBlock={started && !saved} />
        </div>

    )
}

export default FeedbackOverview