import Button from "@/components/button/Button";
import StepperComponent, { Step } from "@/components/stepper/Stepper";
import { useAttributeCategoryTranslation } from "@/general/i18n/translation-helpers";
import { AttributeCategory } from "@/general/model/attribute-category";
import { AttributeFeedback } from "@/general/model/attribute-feedback";
import { Feedback } from "@/general/model/feedback";
import { t } from "i18next";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { USER_FEEDBACK_AGREE, USER_FEEDBACK_DISAGREE, USER_FEEDBACK_NEUTRAL } from '@/general/constants';
import './AttributeEvaluation.scss';

type AttributeEvaluationProps = {
    feedback: Feedback;
    isSubmitting: boolean;
    onSave: (feedbacks: AttributeFeedback[]) => void;
}

function AttributeEvaluation({ feedback, isSubmitting, onSave }: AttributeEvaluationProps) {

    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const [feedbacks, setFeedbacks] = useState<AttributeFeedback[]>(feedback?.attributeFeedbacks);
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [steps, setSteps] = useState<Step[]>([
        {
            stepValue: AttributeCategory.Personality,
            stepLabel: translateAttributeCategory(AttributeCategory.Personality),
            completed: false
        },
        {
            stepValue: AttributeCategory.Skills,
            stepLabel: translateAttributeCategory(AttributeCategory.Skills),
            completed: false
        },
        {
            stepValue: AttributeCategory.Potential,
            stepLabel: translateAttributeCategory(AttributeCategory.Potential),
            completed: false
        }
    ]);
    const filteredFeedbacks = feedbacks?.filter(f => f.attributeCategory == steps[currentStepIndex]?.stepValue);

    function handlePreviousBtn() {
        const updatedSteps = steps.map((step, index) => {
            if (index === currentStepIndex) {
                return { ...step, completed: true };
            }
            return step;
        });
        setSteps(updatedSteps);
        setCurrentStepIndex(currentStepIndex - 1);

        const button = document.querySelectorAll('button');
        button.forEach((button) => {
            button.blur();
        });
    }

    function handleNextBtn() {
        const updatedSteps = steps.map((step, index) => {
            if (index === currentStepIndex) {
                return { ...step, completed: true };
            }
            return step;
        });
        setSteps(updatedSteps);
        setCurrentStepIndex(currentStepIndex + 1);

        const button = document.querySelectorAll('button');
        button.forEach((button) => {
            button.blur();
        });
    }

    function handleStepClick(index: number) {

        if (index > currentStepIndex + 1) {
            return;
        }
        const updatedSteps = steps.map((step, i) => {
            if (i === currentStepIndex) {
                return { ...step, completed: true };
            }
            return step;
        });
        setSteps(updatedSteps);
        setCurrentStepIndex(index);
    }

    const handleFeedbackChange = (attributeId, value) => {

        const updatedFeedbacks = feedbacks.map((feedback) => {
            if (feedback.attributeId === attributeId) {
                return { ...feedback, value: value } as AttributeFeedback;
            }
            return feedback;
        });

        setFeedbacks(updatedFeedbacks);

        const buttons = document.querySelectorAll(`button[data-attribute-id="${attributeId}"]`);
        buttons.forEach((button) => {
            const feedbackValue = button.getAttribute('data-feedback-value');
            if (feedbackValue === String(value)) {
                button.classList.add('option--selected');
            } else {
                button.classList.remove('option--selected');
            }
        });
    };

    return (
        <div className="attribute-evaluation py-5">
            <Row>
                <Col sm={12} className="attribute-evaluation-card">

                    <StepperComponent steps={steps} currentStepIndex={currentStepIndex} stepClickEnabled={true} onHandleStepClick={handleStepClick} />

                    <div className='attribute-list'>

                        {filteredFeedbacks && filteredFeedbacks.length > 0 &&
                            <>
                                <div className="list-header">
                                    <span>{t('feedback:headerAttribute')}</span>
                                    <span>{t('feedback:headerDisagree')}</span>
                                    <span>{t('feedback:headerNeutral')}</span>
                                    <span>{t('feedback:headerAgree')}</span>
                                </div>

                                {filteredFeedbacks && filteredFeedbacks.map((feedback, i) => (
                                    <div className="list-item" key={i}>
                                        <span className="attribute-name">{feedback.attributeName}</span>
                                        <button
                                            data-attribute-id={feedback.attributeId}
                                            data-feedback-value={USER_FEEDBACK_DISAGREE}
                                            className={`feedback-option ${feedback.value === USER_FEEDBACK_DISAGREE ? 'feedback-option--selected' : ''}`}
                                            onClick={() => handleFeedbackChange(feedback.attributeId, -1)} />
                                        <button
                                            data-attribute-id={feedback.attributeId}
                                            data-feedback-value={USER_FEEDBACK_NEUTRAL}
                                            className={`feedback-option ${feedback.value === USER_FEEDBACK_NEUTRAL ? 'feedback-option--selected' : ''}`}
                                            onClick={() => handleFeedbackChange(feedback.attributeId, 0)} />
                                        <button
                                            data-attribute-id={feedback.attributeId}
                                            data-feedback-value={USER_FEEDBACK_AGREE}
                                            className={`feedback-option ${feedback.value === USER_FEEDBACK_AGREE ? 'feedback-option--selected' : ''}`}
                                            onClick={() => handleFeedbackChange(feedback.attributeId, 1)} />
                                    </div>
                                ))}
                            </>
                        }
                    </div>

                    {filteredFeedbacks && filteredFeedbacks.length === 0 &&
                        <div className="no-attributes">
                            <span>{t('feedback:noAttributes', { category: steps[currentStepIndex].stepLabel })}</span>
                        </div>
                    }

                    <div className="actions">
                        {currentStepIndex > 0 &&
                            <Button type='button' displayType="secondary" onClick={() => handlePreviousBtn()} icon="arrow-left" text={t('general:previous')} />
                        }
                        {currentStepIndex < steps.length - 1 &&
                            <Button type='button' displayType="secondary" onClick={() => handleNextBtn()} icon="arrow-right" text={t('general:next')} />
                        }
                    </div>

                    {steps.every((step, index) => step.completed || index == steps.length - 1) &&
                        <div className="complete-action">
                            <Button text={t('general:save')} disabled={isSubmitting} icon="floppy-disk" onClick={() => onSave(feedbacks)} />
                        </div>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default AttributeEvaluation